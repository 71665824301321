<button
  (click)="onClick()"
  [type]="type()"
  class="whitespace-nowrap"
  [ngClass]="getButtonClass()">
  @if(iconSrc() && !loading()) {
    <app-svg-icon [src]="iconSrc()!" [size]="20" class="mr-3" [ngClass]="iconColor"></app-svg-icon>
  }
  @if(loading()) {
    <app-spinner></app-spinner>
  } @else {
    <ng-content></ng-content>
  }
</button>
