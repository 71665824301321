import { Component, inject } from '@angular/core';
import { AppSvgIconComponent } from "../../shared/components/app-svg-icon/app-svg-icon.component";
import { OverviewCardComponent } from "./overview-card/overview-card.component";
import { TopBarComponent } from "../layout/components/topbar/top-bar.component";
import { ExpiringDocumentsCardComponent } from './expiring-documents-card/expiring-documents-card.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TokenStoreService } from '../../core/services/token/token-store.service';
import {BaseComponent} from "../../shared/base/base-component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [AppSvgIconComponent, OverviewCardComponent, TopBarComponent,ExpiringDocumentsCardComponent,MatMenu,MatMenuItem,MatMenuTrigger],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent extends BaseComponent {
  tokenService = inject(TokenStoreService);
  onLogoutClick() {
    this.tokenService.logout();
  }

  onSignupApplicationsClick() {
    this.router.navigate(['company-applications']);
  }
}
