import { Component, input, output } from '@angular/core';
import { NgClass } from '@angular/common';
import { AppSvgIconComponent } from '../../../shared/components/app-svg-icon/app-svg-icon.component';

@Component({
  selector: 'app-expiring-documents-card',
  standalone: true,
  imports: [ AppSvgIconComponent,
    NgClass],
  templateUrl: './expiring-documents-card.component.html',
  styleUrl: './expiring-documents-card.component.scss'
})
export class ExpiringDocumentsCardComponent {
  iconPath = input.required<string>();
  label = input.required<string>();
  count = input.required<number>();
  color = input.required<string>();
  

  onViewClick = output<void>();

  onViewClicked() {
    this.onViewClick.emit();
  }
}


