import {Routes} from '@angular/router';
import {LoginComponent} from './features/auth/login/login.component';
import {HttpClient} from '@angular/common/http';
import {DashboardComponent} from './features/dashboard/dashboard.component';
import {ForgotPasswordComponent} from './features/auth/forgot-password/forgot-password.component';
import {authGuard} from "./core/api/auth.guard";
import {SignupApplicationsComponent} from './features/signup-applications/signup-applications.component';
import {LayoutComponent} from './features/layout/layout.component';

export const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [authGuard]},
  {
    path: '', canActivate: [authGuard], children: [
      {path: 'company-applications', canActivate: [authGuard], component: SignupApplicationsComponent},
    ]
  },
];
