import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideAngularSvgIcon} from "angular-svg-icon";
import { httpTokenInterceptor } from './core/api/interceptors/http-token.interceptor';


export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), 
    provideHttpClient(withInterceptors([httpTokenInterceptor])),provideRouter(routes), provideAnimationsAsync(), provideHttpClient(),  provideAngularSvgIcon(),]
};
