import { Component } from '@angular/core';
import {AppSvgIconComponent} from "../app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-three-dot-menu',
  standalone: true,
    imports: [
        AppSvgIconComponent
    ],
  templateUrl: './three-dot-menu.component.html',
  styleUrl: './three-dot-menu.component.scss'
})
export class ThreeDotMenuComponent {

}
