import {Component, OnInit, inject, signal} from '@angular/core';
import {
  OutlineTextInputComponent
} from "../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {AppSvgIconComponent} from "../../shared/components/app-svg-icon/app-svg-icon.component";
import {MatDialog} from '@angular/material/dialog';
import {BaseComponent} from '../../shared/base/base-component';
import {State} from '../../shared/base/base-state';
import {SidebarMenuComponent} from "../layout/components/topbar/sidebar/sidebar-menu/sidebar-menu.component";
import {SidebarComponent} from "../layout/components/topbar/sidebar/sidebar.component";
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {NgClass} from '@angular/common';
import {CommonModule} from '@angular/common';
import {AlertDialogComponent} from '../../shared/components/alert-dialog/alert-dialog.component';
import {AlertDialogService} from '../../shared/components/alert-dialog/alert-dialog.service';
import {ThreeDotMenuComponent} from "../../shared/components/three-dot-menu/three-dot-menu.component";
import {BaseTableComponent, PagingEvent} from "../../shared/components/_base/base-table/base-table.component";
import {SignupApplicationService} from "../../core/services/signup-applications/signup-applications.service";
import {
  AcceptSignupApplicationRequest
} from "../../core/services/signup-applications/accept-signup-application/accept-signup-application.request";
import {
  RejectSignupApplicationRequest
} from "../../core/services/signup-applications/reject-signup-application/reject-signup-application.request";
import {GenericResponse} from "../../core/services/generic-response";
import {TopBarComponent} from "../layout/components/topbar/top-bar.component";
import {ApiService} from "../../core/api/api.service";
import type {GetCompanyApplicationListParams, GetCompanyApplicationListResponse} from "../../core/api/model";
import {Constants} from "../../core/constants/constants";

@Component({
  selector: 'app-signup-applications',
  standalone: true,
  imports: [OutlineTextInputComponent, ButtonComponent, AppSvgIconComponent, SidebarMenuComponent, SidebarComponent, MatButtonModule, MatMenuModule, MatIconModule, NgClass, CommonModule, AlertDialogComponent, ThreeDotMenuComponent, BaseTableComponent, TopBarComponent],
  templateUrl: './signup-applications.component.html',
  styleUrl: './signup-applications.component.scss'
})
export class SignupApplicationsComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  alertDialog = inject(AlertDialogService);
  applicationService = inject(SignupApplicationService);
  apiService = inject(ApiService);


  applicationsState = new State<GetCompanyApplicationListResponse>();
  acceptApplicationState = new State<GenericResponse>();
  rejectApplicationState = new State<GenericResponse>();

  applicationStatus = signal(false);


  ngOnInit(): void {

  }

  getApplications($event: PagingEvent) {
    let request: GetCompanyApplicationListParams = {
      pageNumber: $event.pageNumber,
      pageSize: $event.pageSize,
    };
    this.executeRequest<GetCompanyApplicationListResponse>({
      state: this.applicationsState,
      request: this.apiService.getCompanyApplicationList(request),
      onSuccess(response) {
        console.log(response);
      },
    });
  }

  onAcceptClicked(companyApplicationId: string) {
    this.alertDialog.show('Approve', 'Are you sure you want to accept this application?')
      .subscribe(action => {
        if (action) {
          const request: AcceptSignupApplicationRequest = {
            companyApplicationId
          };
          this.executeRequest<GenericResponse>({
            state: this.acceptApplicationState,
            request: this.applicationService.approveApplication(request),
            handleSuccess: true,
            onSuccess: response => {
              this.getApplications(Constants.defaultPaginationParams);
            }
          });
        }
      });
  }

  onRejectClicked(companyApplicationId: string) {
    this.alertDialog.show('Reject', 'Are you sure you want to reject this application?')
      .subscribe(action => {
          if (action) {
            const request: RejectSignupApplicationRequest = {
              companyApplicationId
            };
            this.executeRequest<GenericResponse>
            ({
              state: this.rejectApplicationState,
              request: this.applicationService.rejectApplication(request),
              handleSuccess: true,
              onSuccess: response => {
                this.getApplications(Constants.defaultPaginationParams);
              }
            });
          }
        }
      );
  }

}














