<div class="inline-flex items-center cursor-pointer select-none" [ngClass]="fullWidth() ? 'w-full' : ''">
  <div class="flex gap-6 items-center w-full">
    <div class="flex flex-col justify-center gap-2 w-full">
      @if(title()) {
        <p class="text-body2 text-primary-900">{{ title() }}</p>
      }
      <ng-content class="w-full" select="[input]"></ng-content>
      <div>
        <ng-content select="[error]"></ng-content>
      </div>
    </div>
    <ng-content select="[icon]"></ng-content>
  </div>
</div>
