<div class="flex p-3 rounded-lg gap-4 items-center justify-start bg-opacity-10" [ngClass]="color()">
  <div class="flex items-center justify-center w-10 h-10 rounded-full" [ngClass]="color()">
    <app-svg-icon class="text-white" [src]="iconPath()"></app-svg-icon>
  </div>
  <div class="flex flex-col gap-2">
    <p class="text-caption text-neutral-900 text-nowrap">{{ label() }}</p>
    <div class="flex items-center gap-3">
      <p class="text-h6 text-neutral-900">{{ count() }}</p>
      <app-svg-icon [size]="16" class="text-neutral-400 cursor-pointer" src="assets/icons/eye.svg"
        (click)="onViewClicked()"></app-svg-icon>
    </div>
  </div>
</div>