<!--<div class="relative flex flex-col items-start justify-center focus-within:text-neutral-600"-->
<!--     [ngClass]="fullWidth() ? 'w-full' : ''">-->
<!--  @if (label()) {-->
<!--    <span class="text-body2 text-primary-900">{{ label() }}<span *ngIf="isRequired" class="text-red-500 ml-1">*</span></span>-->
<!--  }-->

<!--  <div class="relative flex items-center w-full text-neutral-400 my-2 bg-white shadow-1 rounded-md">-->
<!--    @if (iconSrc()) {-->
<!--      <app-svg-icon [size]="18" [src]="iconSrc()!"-->
<!--                    class="absolute pointer-events-none ml-3 text-neutral-400"></app-svg-icon>-->
<!--    }-->

<!--    <input-->
<!--      class="h-[46px] placeholder-neutral-400 text-black rounded-md border-none outline-none focus:border-none ring-1 ring-neutral-300 focus:ring-primary-500 focus:ring-2 transition-all duration-300"-->
<!--      [formControl]="formControl" [ngClass]="getClass()" [type]="type()" [placeholder]="placeholder()"-->
<!--      (blur)="onTouched()" (input)="onValueChange($event)">-->

<!--    @if (actionIcon()) {-->
<!--      <div (click)="onActionClicked()"-->
<!--           class="absolute right-2 p-2 flex justify-center items-center hover:bg-neutral-100 rounded-full">-->
<!--        <app-svg-icon [size]="18" [src]="actionIcon()!" class="text-neutral-400 cursor-pointer">-->
<!--        </app-svg-icon>-->
<!--      </div>-->
<!--    }-->
<!--  </div>-->


<!--  @if (formControl.errors && hasErrors) {-->
<!--    <div class="mb-1">-->
<!--      <span class="text-caption text-red-500">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</span>-->
<!--    </div>-->
<!--  } @else {-->
<!--    @if (showErrorSpace()) {-->
<!--      <div class="mb-1">-->
<!--        <span class="text-caption text-transparent">.</span>-->
<!--      </div>-->
<!--    }-->
<!--  }-->

<!--</div>-->


<app-base-input [title]="label()" [fullWidth]="fullWidth()">
  <div input class="relative flex items-center w-full text-neutral-400 bg-white shadow-1 rounded-md">
    @if (iconSrc()) {
      <app-svg-icon [size]="18" [src]="iconSrc()!"
                    class="absolute pointer-events-none ml-3 text-neutral-400"></app-svg-icon>
    }

    <input
      class="h-[46px] placeholder-neutral-400 text-black rounded-md border-none outline-none focus:border-none ring-1 ring-neutral-300 focus:ring-primary-500 focus:ring-2 transition-all duration-300"
      [class.text-neutral-600]="formControl.disabled"
      [class.disabled-placeholder]="formControl.disabled"
      [formControl]="formControl"
      [ngClass]="getClass()"
      [type]="type()"
      [placeholder]="placeholder()"
      (blur)="onTouched()"
      (input)="onValueChange($event)">

    @if (actionIcon()) {
      <div (click)="onActionClicked()"
           class="absolute right-2 p-2 flex justify-center items-center hover:bg-neutral-100 rounded-full">
        <app-svg-icon [size]="18" [src]="actionIcon()!" class="text-neutral-400 cursor-pointer">
        </app-svg-icon>
      </div>
    }
  </div>
  @if (formControl.errors && hasErrors) {
    <div class="mb-1">
      <span class="text-caption text-red-500">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</span>
    </div>
  } @else {
    @if (showErrorSpace()) {
      <div class="mb-1">
        <span class="text-caption text-transparent">.</span>
      </div>
    }
  }
</app-base-input>

@if (formControl.errors && hasErrors) {
  <div class="mb-1">
    <span class="text-caption text-red-500">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</span>
  </div>
} @else {
  @if (showErrorSpace()) {
    <div class="mb-1">
      <span class="text-caption text-transparent">.</span>
    </div>
  }
}
