import {inject, Injectable, signal} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenStoreService {
  router = inject(Router);

  storage: Storage;
  private static REFRESH_TOKEN_KEY = 'refresh_token';
  private static ACCESS_TOKEN_KEY = 'access_token';

  isLoggedIn = signal<boolean>(false);

  constructor() {
    this.storage = localStorage;
    const token = this.getToken();
    if (token.refreshToken && token.accessToken) {
      this.isLoggedIn.update(() => true);
      console.log('true');
    } else {
      this.isLoggedIn.update(() => false);
      console.log('false');
    }
  }

  getToken() {
    return {
      refreshToken: this.storage.getItem(TokenStoreService.REFRESH_TOKEN_KEY),
      accessToken: this.storage.getItem(TokenStoreService.ACCESS_TOKEN_KEY)
    };
  }

  saveToken(refreshToken: string, accessToken: string) {
    this.storage.setItem(TokenStoreService.REFRESH_TOKEN_KEY, refreshToken);
    this.storage.setItem(TokenStoreService.ACCESS_TOKEN_KEY, accessToken);
    this.isLoggedIn.update(() => true);
  }


  logout() {
    this.isLoggedIn.set(false);
    this.storage.removeItem(TokenStoreService.REFRESH_TOKEN_KEY);
    this.storage.removeItem(TokenStoreService.ACCESS_TOKEN_KEY);
    this.router.navigate(['/']);
  }
}
