<app-top-bar></app-top-bar>
<div class="container-fluid bg-neutral-50">
  <div class="container p-4 mx-auto flex flex-col gap-4">
    <div class="flex gap-2">
      <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                              [fullWidth]="false">
      </app-outline-text-input>
    </div>

    <app-base-table [state]="applicationsState" (pageChange)="getApplications($event)">
      <thead head>
      <tr class="border-b border-neutral-200 text-overline text-neutral-500 text-nowrap">
        <th class="p-4 text-left w-1/6">FULL NAME</th>
        <th class="p-4 text-left w-1/6">CONTACT</th>
        <th class="p-4 text-center w-1/6">COMPANY / INDIVIDUAL</th>
        <th class="p-4 text-center w-1/6">BUSINESS ENTITY</th>
        <th class="p-4 text-center w-1/6">STATUS</th>
        <th class="p-4 text-center w-1/6"></th>
      </tr>
      </thead>
      <tbody body>
        @for (item of applicationsState.response()?.data; track item.email; let idx = $index, e = $even) {
          <tr class="border-b border-neutral-100 w-full space-x-4">
            <td class="p-3 text-left w-1/6">
              <div class="flex flex-col gap-2">
                <span class="text-body2">{{ item.firstName }}</span>
                <span class="text-caption text-neutral-500 text-nowrap">{{ item.applicationNumber }}</span>
              </div>
            </td>
            <td class="p-3 text-left w-1/6">
              <div class="text-info-500 text-body2 py-1">{{ item.email }}</div>
<!--              <div class="text-caption justify-center text-neutral-500">{{ item.phoneNumber }}</div>-->
            </td>
            <td class="p-3 text-center w-1/6">
              <span class="text-body2">{{ item.companyName }}</span>
            </td>
            <td class="p-3 text-center w-1/6">
              <span class="text-body2">{{ item.companyType }}</span>
            </td>
            <td class="p-3 text-center w-1/6">
            <span class="text-body2 justify-center items-center rounded-full py-2 px-3" [ngClass]="{
                        'bg-yellow-50 text-yellow-500': item.companyApplicationStatus === 'Pending',
                        'bg-info-50 text-info-500': item.companyApplicationStatus === 'Open',
                        'bg-success-50 text-success-500' : item.companyApplicationStatus === 'Completed',
                        'bg-red-50 text-error-500' : item.companyApplicationStatus === 'Rejected',
                      }">
              {{ item.companyApplicationStatus }}
            </span>
            </td>
            <td class="p-3 text-center w-1/6">
              <div class="flex justify-center items-center">
                @if(item.companyApplicationStatus==="Open"){
                <app-three-dot-menu [matMenuTriggerFor]="menu"></app-three-dot-menu>}
                <mat-menu #menu="matMenu" class="custom-mat-menu w-48" xPosition="after" yPosition="below">
                  <button mat-menu-item class="menu-item p-3">
                                          <span
                                            class="text-caption text-center flex justify-center items-center rounded-lg bg-white border border-neutral-300 px-6 py-3"
                                            (click)="onAcceptClicked(item.id)">Accept</span>
                  </button>
                  <button mat-menu-item class="menu-item p-3">
                                  <span
                                    class="text-caption text-center flex justify-center items-center rounded-lg bg-white border border-neutral-300 px-6 py-3"
                                    (click)="onRejectClicked(item.id)">Reject</span>
                  </button>
                </mat-menu>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </app-base-table>
  </div>
</div>
