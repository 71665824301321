<div class="flex py-4 px-4 border-b border-neutral-200">
  <div class="flex gap-2 items-center">
    <img [height]="7" [width]="120" src="../../../../assets/images/ysc-logo.png" alt="Logo" class="mx-auto">
  </div>
  <div class="flex gap-5 justify-end items-center ml-auto">
    <app-svg-icon class="text-neutral-400 cursor-pointer" [size]="20"
                  src="assets/icons/notification.svg"></app-svg-icon>

    <div [matMenuTriggerFor]="accountMenu" class="flex gap-4 items-center cursor-pointer">
      <app-svg-icon class="text-neutral-400" src="assets/icons/account-circle.svg"></app-svg-icon>
      <app-svg-icon class="text-neutral-400" [size]="14" src="assets/icons/chevron-down.svg"></app-svg-icon>
    </div>
  </div>
</div>

<div class="container p-4 mx-auto h-dvh my-auto">
  <mat-menu class="mat-elevation-z3 mt-5" #accountMenu="matMenu">
    <div class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
      <app-svg-icon src="assets/icons/account-circle.svg" class="text-neutral-500 cursor-pointer"
                    [size]="18"></app-svg-icon>
      <span class="text-body2">Account</span>
    </div>
    <div class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100"
         (click)="onLogoutClick()">
      <app-svg-icon src="assets/icons/logout.svg" class="text-neutral-500 cursor-pointer" [size]="18"></app-svg-icon>
      <span class="text-body2">Logout</span>
    </div>
  </mat-menu>
  <div class="flex flex-col justify-center gap-5 my-6">

    <p class="text-body1">Main Menu</p>

    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <app-overview-card [iconPath]="'assets/icons/domain.svg'" [label]="'Companies'"></app-overview-card>
      <app-overview-card [iconPath]="'assets/icons/new-application.svg'"
                         [label]="'New Applications'" (click)="onSignupApplicationsClick()"></app-overview-card>
      <app-overview-card [iconPath]="'assets/icons/chart-line.svg'" [label]="'Reports'"></app-overview-card>
      <app-overview-card [iconPath]="'assets/icons/settings.svg'" [label]="'Settings'"></app-overview-card>
    </div>

<!--    <p class="text-body1">Overview</p>-->

<!--    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4">-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/active-companies.svg'" [label]="'Active Companies'"-->
<!--                                   [count]="0" [color]="'bg-blue-400'"></app-expiring-documents-card>-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/active-companies.svg'" [label]="'Inactive Companies'"-->
<!--                                   [count]="0" [color]="'bg-purple-400'"></app-expiring-documents-card>-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/card-account-details.svg'" [label]="'Drivers'"-->
<!--                                   [count]="0" [color]="'bg-pink-400'"></app-expiring-documents-card>-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/bus.svg'" [label]="'Equipment'" [count]="0"-->
<!--                                   [color]="'bg-pink-400'"></app-expiring-documents-card>-->
<!--    </div>-->
<!--    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-4">-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/notification.svg'" [label]="'Renewals this Month'"-->
<!--                                   [count]="0" [color]="'bg-yellow-400'"></app-expiring-documents-card>-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/card-account-details.svg'" [label]="'Expired'"-->
<!--                                   [count]="0" [color]="'bg-purple-400'"></app-expiring-documents-card>-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/card-account-details.svg'" [label]="'Document Pending'"-->
<!--                                   [count]="0" [color]="'bg-pink-400'"></app-expiring-documents-card>-->
<!--      <app-expiring-documents-card [iconPath]="'assets/icons/bus.svg'" [label]="'Suspended'" [count]="0"-->
<!--                                   [color]="'bg-pink-400'"></app-expiring-documents-card>-->
<!--    </div>-->
  </div>

</div>

