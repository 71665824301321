import {Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {URLs} from "../../api/urls";
import {AcceptSignupApplicationRequest} from "./accept-signup-application/accept-signup-application.request";
import {RejectSignupApplicationRequest} from "./reject-signup-application/reject-signup-application.request";
import {SignupApplicationResponse} from "./signup-applications/signup-applications-response";

@Injectable({
  providedIn: 'root'
})
export class SignupApplicationService extends BaseApiService {

  getApplication() {
    return this.get<SignupApplicationResponse>(URLs.applications);
  }

  approveApplication(request: AcceptSignupApplicationRequest) {
    return this.put(URLs.acceptApplication, request);
  }

  rejectApplication(request: RejectSignupApplicationRequest) {
    return this.put(URLs.rejectApplication, request);
  }
}
