<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">
    <p class="text-body1 text-error">{{ data.title }}</p>
    <p class="text-body2">{{ data.message }}</p>
    <div class="flex justify-end gap-4 mt-3">
      <app-button appearance="outline" (click)="onNoClicked()">Cancel
      </app-button>
      <app-button  buttonColor="bg-error-500" appearance="outline" (click)="onYesClicked()">Yes
      </app-button>
    </div>
  </div>
</mat-dialog-content>
