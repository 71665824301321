import { Component, Input, inject, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { RouterLink } from '@angular/router';
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { TokenStoreService } from "../../../../core/services/token/token-store.service";

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    AppSvgIconComponent, CommonModule, RouterLink, MatMenu, MatMenuItem, MatMenuTrigger
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent {

  tokenService = inject(TokenStoreService);

  onLogoutClick() {
    this.tokenService.logout();
  }
}
