import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private _sidebarExpanded = signal(true);

  get sidebarExpanded() {
    return this._sidebarExpanded();
  }

  public toggleSidebar() {
    this._sidebarExpanded.update(curr => !curr);
  }
}
