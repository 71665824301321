<div class="bg-white rounded-lg">
  <table class="w-full">
    <ng-content select="[head]"></ng-content>
    <ng-content select="[body]"></ng-content>
    @if (state()?.loading()) {
      <tbody class="bg-white text-center py-14">
      <tr>
        <td colspan="100">
          <div class="flex flex-col gap-8 px-6 py-8">
            <app-shimmer type="list"></app-shimmer>
            <app-shimmer type="list"></app-shimmer>
            <app-shimmer type="list"></app-shimmer>
            <app-shimmer type="list"></app-shimmer>
            <app-shimmer type="list"></app-shimmer>
            <app-shimmer type="list"></app-shimmer>
            <app-shimmer type="list"></app-shimmer>
            <app-shimmer type="list"></app-shimmer>
          </div>
        </td>
      </tr>
      </tbody>
    }

    @if (state()?.success() && (state()?.response()?.data ?? []).length <= 0) {
      <app-no-data-table></app-no-data-table>
    }
  </table>
  <div class="bg-white">
    <mat-paginator class="bg-white" [length]="state()?.response()?.totalCount"
                   [pageSize]="pageSize()"
                   (page)="onPageChange($event)"
                   [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </div>
</div>
