import {Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {URLs} from '../../api/urls';
import {LoginRequest} from './login/login-request';
import {LoginResponse} from './login/login-response';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {
  login(request: LoginRequest) {
    return this.post<LoginResponse>(URLs.login, request);
  }
}
